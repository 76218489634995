.stories {
    padding-top: 25px;
    overflow: hidden;

    .stories-single {
        position: relative;
        padding-top: 50px;
        padding-bottom: 100px;

        .mirrored {
            .active-border {
                position: absolute;
                height: 1px;
                width: 120%;
                bottom: -16px;
                background: var(--primary-color);
                content: '';
                margin-left: auto;
            }

         
            .content {
                * {
                    text-align: right;
                }

                span {
                    display: block;
                    
                }
            }

            .active-border {
                position: absolute;
                height: 1px;
                width: 120%;
                bottom: -16px;
                left: -20%;
                background: var(--primary-color);
                content: '';
                margin-left: auto;
            }
        }
        
        a {
            text-decoration: none;

            .content {
                width: 45%;

                h2 {
                    position: relative;
                    margin-bottom: 36px;
                }

                span {
                    text-decoration: underline;
                }
            }

            img {
                width: 50%;
                height: 416px;
                object-fit: cover;
            }
        }

        .active-border {
            position: absolute;
            height: 1px;
            width: 120%;
            bottom: -16px;
            background: var(--primary-color);
            content: '';
            margin-left: auto;
        }
    }
}

@media screen and (max-width: 760px) {
    .stories {
        padding-bottom: 100px;
        
        .stories-single {
            padding-top: 0;
            padding-bottom: 80px;
            
            a {
                flex-wrap: wrap-reverse;

                .content {
                    width: 100%;
                }
    
                img {
                    width: 100%;
                    height: auto;
                }
            }
            

            .mirrored {
                flex-wrap: wrap-reverse;

                .content {
                    * {
                        text-align: left;
                    }
                }
            }
        }
    }
}