.inspiratie-grid-header {
    display: flex;
    justify-content: space-between;
    align-content: center;
    h3 {
        font-size: 50px;
        font-weight: bolder;
    }
    p {
        opacity: 0.5;
        align-self: end;
        padding: 0;
        margin: 0;
    }
    margin: 2rem 0;
}

.inspiratie-grid__inner {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    gap: 1rem;

    @for $i from 1 to 13 {
        .col-span-#{$i} {
            grid-column: span $i / span $i;
        }
    }

    img {
        height: 100%;
        object-fit: cover;
    }
}

// // Predefined Break-points
// $laptop-min: 1201px;
// $ipad: 1200px;
// $mob: 700px;
// $gutter: (0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100);
// @for $value from 1 through 100 {
//     @each $gut in $gutter {
//         $width: percentage($value/100);
//     }
// }
