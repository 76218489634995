#navbar {
  padding: 1.5rem 15px;
  position: fixed;
  width: 100%;
  z-index: 99;
  background-color: #f2f0ef;

  .nav-links {
    color: var(--text_color);
    margin: 0 24px;
    font-size: 15px;
    font-weight: 400;
    text-decoration: none;
  }

  .logo {
    img {
      height: 26px;
      width: auto;
    }
  }
}

.nav-spacer {
  height: 75px;
}

.mega-menu-toggle {
  margin-left: 24px;
  cursor: pointer;
}

.mega-nav {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--background_color);
  padding-bottom: 1rem;
  transform: translateX(-100%);
  transition: 0.4s linear;

  .mega-nav-header {
    padding: 1rem 15px;

    .logo {
      img {
        height: 26px;
        width: auto;
      }
    }
  }

  .mega-menu-content {
    padding: 30px 15px;

    .col {
      width: 50%;

      a {
        position: relative;
        font-size: 45px;
        font-weight: 700;
        text-decoration: none;
        display: block;
        margin-bottom: 24px;
        text-transform: uppercase;
        opacity: 0.5;
        transition: 0.2s ease-in-out;

        &:hover {
          text-decoration: underline;
        }

        .active-border {
          display: none;
        }

        &.active {
          opacity: 1;

          .active-border {
            display: block;
            position: absolute;
            height: 1px;
            width: 50%;
            top: 20px;
            left: -56%;
            background: var(--primary-color);
            content: "";
            margin-left: auto;
          }
        }
      }
    }

    .col-big {
      a {
        transform: translateX(-100%);
        transition: 0.3s ease-in-out;

        .active-border {
          transition: 0.3s ease-in-out;
          transition-delay: 1.5s;
          opacity: 0;
        }
      }

      @for $i from 1 through 5 {
        a:nth-child(#{$i}) {
          transition-delay: calc($i / 6) + s;
        }
      }
    }

    .col.col-small {
      transition: 0.3s ease-in;
      transition-delay: 1.3s;
      opacity: 0;

      a {
        font-size: 24px;
        font-weight: 400;
        text-decoration: none;
        display: block;
        margin-bottom: 20px;
        text-transform: none;
        opacity: 1;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .mega-menu-foot {
    opacity: 0;
    transition: 0.3s ease-in;
    transition-delay: 1s;

    .social-row {
      a {
        margin-right: 10px;
      }
    }
  }
}

.mega-nav.active {
  transform: translateX(0);

  .mega-menu-foot {
    opacity: 1;
  }

  .mega-nav-header {
    .logo {
      img {
        height: 16px;
        width: auto;
      }
    }
  }

  .mega-menu-content {
    .col.col-small {
      opacity: 1;
    }

    .col-big {
      a {
        transform: translateX(0);

        .active-border {
          opacity: 1;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  #navbar {
    #nav-links-container {
      display: none;
    }
  }

  .mega-nav {
    .mega-menu-content {
      .col {
        a {
          font-size: 30px;
        }
      }

      .col.col-small {
        a {
          font-size: 18px;
        }
      }
    }
  }
}

@media screen and (max-width: 760px) {
  #navbar {
    .logo {
      img {
        height: 16px;
        width: auto;
      }
    }
  }

  .mega-nav {
    .mega-menu-content {
      flex-wrap: wrap;

      .col {
        width: 100%;

        a {
          font-size: 24px;
        }
      }

      .col.col-small {
        a {
          font-size: 16px;
        }
      }
    }

    .mega-menu-foot {
      .social-row {
        display: none;
      }

      .mail {
        display: none;
      }
    }
  }
}
