section.list-image {
    margin: 3rem 0;
    height: 466px;
    position: relative;

    .list-image__wrap {
        height: 100%;
        
        .info {
            width: calc(50% - 1rem);

            h1 {
                font-size: 65px;
                text-transform: uppercase;
                margin-top: 0;
            }

            p {
                font-size: 24px;
            }

            .list {
                width: 100%;
                gap: 1.5rem 1rem;

                .list__item {
                    width: calc(50% - .5rem);
                    font-weight: 300;
                }
            }
        }
        .image {
            width: calc(50% - 1rem);
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            object-fit: cover;
        }
    }
}

@media screen and (max-width: 1024px) {
    section.list-image {

        .list-image__wrap {
            .info {
                h1 {
                    font-size: 45px;
                }
            }
        }
    }
}

@media screen and (max-width: 860px) {
    section.list-image {
        height: auto;
        margin-top: 36px;

        .list-image__wrap {
            flex-wrap: wrap-reverse;

            .info {
                width: 100%;
                padding-bottom: 45px;
            }

            .image {
                width: 100%;
                position: relative;
                margin-bottom: 26px;
            }
        }
    }
}

@media screen and (max-width: 560px) {
    section.list-image {

        .list-image__wrap {
            .info {
                h1 {
                    font-size: 26px;
                }

                p {
                    font-size: 20px;
                }

                .list {
                    .list__item {
                        width: 100%;
                        font-weight: 300;
                    }
                }
            }
        }
    }
}