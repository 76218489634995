.hero-product {
    margin-top: -74px;

    .col-intro {
        width: 40%;
        position: relative;
        z-index: 10;

        h1 {
            font-size: 70px;
            margin-bottom: 20px;

            span {
                font-size: 70px;
            }
        }

        .intro {
            max-width: 60%;
        }

        .gallery-nav {
            margin-top: 60px;

            .nav-item {
                font-size: 24px;
                margin-bottom: 32px;
                cursor: pointer;
                position: relative;
                white-space: nowrap;

                .active-border {
                    height: 1px;
                    width: 100%;
                    background: var(--primary-color);
                    margin-left: auto;
                    margin-top: auto;
                    margin-bottom: auto;
                }

                .nav-item-space {
                    width: 15px;
                }

                &:hover {
                    color: var(--primary-color);
                    transition: .1s ease-in;
                }
            }

            .nav-item.active {
                color: var(--primary-color);
            }
        }
    }

    .gallery {
        width: 84%;
        height: 725px;
        margin-right: -25%;
        position: relative;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

@media screen and (max-width: 1280px) {
    .hero-product {
        .col-intro { 
            width: 55%;
        }

        .gallery {
            width: 80%;
        }
    }
}

@media screen and (max-width: 1024px) {
    .hero-product {
        flex-wrap: wrap-reverse;
        margin-top: 0;

        .col-intro { 
            width: 100%;

            .gallery-nav {
                order: 1;
                flex-direction: row;
                margin-top: 12px;

                .nav-item {
                    margin-right: 26px;
                    white-space: normal;

                    .active-border {
                        display: none;
                    }
                }
            }

            h1 {
                order: 2;
                font-size: 42px;
                margin-top: 12px;

                span {
                    font-size: 42px;
                }
            }

            .intro {
                order: 3;
            }
        }

        .gallery {
            height: auto;
            width: 100%;
            margin: 0;
        }
    }
}