.dealers {
  .header {
    h1 {
      font-size: 65px;
    }
  }

  .dealers-list {
    aside {
      width: 220px;

      h4 {
        font-size: 24px;
        margin-bottom: 26px;
      }

      .filter {
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
      }

      .wrap {
        gap: 18px;
      }
    }

    .list {
      width: 100%;
      gap: 20px;

      .dealer-list-item {
        width: 100%;
        padding: 0 15px;
        position: relative;

        .content {
          width: 100%;

          .dealer-logo {
            width: 120px;
            margin-right: 30px;

            img {
              width: 100%;
              height: auto;
            }
          }

          .body {
            padding-top: 20px;
          }

          .info {
            padding-left: 26px;

            .head {
              padding: 20px 0;

              .icon {
                position: absolute;
                top: 20px;
                right: 30px;
              }
              a,
              h3 {
                font-size: 18px;
                font-weight: 700;
              }
            }
          }
        }

        .foot {
          padding-top: 10px;
          padding-bottom: 30px;

          .locatie {
            margin-right: 18px;
          }

          .products-row {
            .product {
              margin-right: 16px;

              img {
                margin-right: 4px;
              }

              a {
                font-weight: 300;
              }
            }
          }
        }
      }
    }
  }
}

.maps-container {
  .wrap {
    width: 100%;
  }
}

@media screen and (max-width: 960px) {
  .dealers {
    .header {
      h1 {
        font-size: 42px;
      }
    }

    .dealers-list {
      flex-wrap: wrap;
      overflow: hidden;

      aside {
        width: 100%;
        padding-bottom: 36px;

        h4 {
          margin-bottom: 6px;
        }

        .filter {
          scroll-behavior: smooth;
          gap: 8px;

          .filter__item {
            display: block;
          }
        }
      }

      .list {
        width: 100%;

        .dealer-list-item {
          .content {
            .info {
              .head {
                flex-direction: column;

                h3 {
                  margin-bottom: 6px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 560px) {
  .dealers {
    .dealers-list {
      .list {
        .dealer-list-item {
          .content {
            flex-direction: column;

            img {
              width: 100%;
              height: auto;
            }

            .info {
              width: 100%;
              padding: 0.5rem 0 0 0;
            }
          }

          .foot {
            padding-top: 12px;
            flex-direction: column;

            .locatie {
              margin-bottom: 6px;
            }

            .products-row {
              flex-wrap: wrap;

              .product {
                margin-bottom: 6px;

                img {
                  filter: invert(1);
                }
              }
            }
          }
        }
      }
    }
  }
}
