.hero-banner-text {
    .hero-img {
        width: 120%;
        height: 360px;
        margin-right: -20%;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .col {
        width: 50%;
        padding-top: 50px;
    }
}

@media screen and (max-width: 760px) {
    .hero-banner-text {
        .hero-content {
            flex-direction: column;
        }

        .col {
            width: 100%;
            padding-top: 0;
        }
    } 
}