.page-container {
    .kennisbank-header {
  

        h1 {
            font-size: 55px;
            text-transform: uppercase;
            margin: 0 26px 0 0;

            @media screen and (max-width: 1023px) {
                font-size: 32px;
            }
        }
        
        .line {
            content: '';
            height: 1px;
            background: var(--primary-color);
            height: 1px;
            width: 12.5%;
            margin: 0 26px 0 0;
        }

        p {
            font-size: 30px;
            font-weight: bold;
            text-transform: uppercase;
            margin: 0;
            
            @media screen and (max-width: 1023px) {
                font-size: 22px;
            }
        }
    }
}
