.product-grid {
    padding-top: 20px;
    min-height: 900px;

    .assortiment-single {
        position: relative;
        margin-bottom: 20px;
        height: 416px;
        width: calc(33.333% - 10px);

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .content--hover {
            position: absolute;
            z-index: 10;
            opacity: 0;
            bottom: 20px;
            left: 20px;
            transition: .3s ease-in-out;

            h3 {
                font-size: 24px;
                color: #fff;
            }
        }
        
        &:nth-child(1) {
            width: calc(60% - 10px);
        }

        &:nth-child(2) {
            width: calc(40% - 10px);
        }

        &:nth-child(3) {
            width: calc(45% - 10px);
        }

        &:nth-child(4) {
            width: calc(55% - 10px);
        }

        &:hover {
            .content--hover {
                opacity: 1;
            }
        }
    }
}

@media screen and (max-width: 760px) {
    .product-grid {
    
        .assortiment-single {
            width: 100% !important;

            .content--hover {
                opacity: 1;
            }
        }
    }
}