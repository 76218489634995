section.downloads {
    margin-top: 3rem;
    position: relative;
    .downloads-header {
     
        margin-right: 50px;
        .downloads-header-content {
            margin-bottom: 20px;
        }

        .radio-container {
            display: flex;
            flex-direction: column;
            margin-bottom: 1.75rem;

            input[type=checkbox] {
                visibility: hidden;
                width: 0;
    
                & + label {
                    display: flex;
                    align-items: center;
    
                    &::before {
                        content: '';
                        width: 12px;
                        height: 12px;
                        border: 1px solid var(--text_color);
                        margin-right: .5rem;
                    }
                }
    
                &:checked {
                    & + label {
                        &::before {
                            background: var(--text_color);
                        }
                    }
                }
            }

            h3 {
                font-size: 24px;
                font-weight: bold;
            }
        }
    }
    .downloads-content {

        .downloads-grid-content-grid {
            .grid-item {
                
                img {
                    width: 100%;
                    height: 200px;
                    object-fit: cover;
                }

                .grid-item-content {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 10px;

                    .content-main {
                        display: flex;
                        flex-direction: column;
                        flex-basis: 80%;

                        h3 {
                            font-size: 16px;
                            font-weight: 700;
                        }

                        p {
                            opacity: 0.8;
                        }
                    }

                    .content-extra {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;

                        .optinal-pdf {
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: flex-end;
                            gap: 10px;

                            img {
                                width: 15px;
                                height: 15px;
                                filter: brightness(0) saturate(100%) invert(45%) sepia(83%) saturate(501%)
                                    hue-rotate(352deg) brightness(99%) contrast(100%);
                            }

                            a {
                                font-size: 12px;
                            }
                        }

                        .optinal-video {
                            border-radius: 50%;
                            background-color: white;
                            width: 17.5px;
                            height: 17.5px;

                            display: flex;
                            justify-content: center;
                            align-items: center;

                            img {
                                object-fit: contain;
                                width: 12.5px;
                                height: 12.5px;
                                padding-left: 2.5px;
                            }

                            &:hover {
                                cursor: pointer;
                            }
                        }

                        p {
                            font-size: 12px;
                        }
                        img {
                            width: 25px;
                            height: 25px;
                            object-fit: contain;
                        }
                    }
                }
            }
        }
    }
}

.popup-model {
    position: fixed;
    top: 100px;
    right: 10%;
    width: 80%;
    height: 80vh;
    min-height: 250px;
    z-index: 25;

    background-color: gray;
    border-top-left-radius: 3rem;
    border-top-right-radius: 1rem;
    border-bottom-left-radius: 3rem;
    border-bottom-right-radius: 3rem;

    display: flex;
    flex-direction: column;
    padding: 4rem;
    text-align: center;
    align-items: center;

    h3 {
        font-weight: bold;
        font-size: 46px;
    }
    h4 {
        font-size: 36px;
        // text-align: left;
    }
    span {
        p {
            margin-bottom: 1px;
        }
    }
    video {
        width: 100%;
        height: 500px;
    }
    img {
        border: 1px solid;
        border-radius: 50%;
        width: 25px;
        height: 25px;

        // filter: brightness(0) saturate(100%) invert(45%) sepia(83%) saturate(501%) hue-rotate(352deg) brightness(99%)
        //     contrast(100%);
        color: var(--text_color);

        position: absolute;
        top: 1rem;
        right: 1rem;

        &:hover {
            transition: 150ms ease-in-out;
            background-color: red;
        }
    }
}
