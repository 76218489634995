.hero-contact {
  margin-top: -70px;

  .wrap {
    .content {
      width: 65%;
      padding-right: 75px;

      h1 {
        font-size: 65px;
        text-transform: uppercase;
        margin-bottom: 12px;
      }

      .cols {
        width: 100%;
        padding-top: 26px;

        .col {
          width: 50%;
        }
      }

      .gform_wrapper {
        * {
          color: var(--text_color);
        }
        .gform-body {
          .gform_fields {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            gap: 0.5rem 3rem;

            .gfield {
              input[type="text"],
              input[type="email"],
              input[type="tel"],
              input[type="number"],
              input[type="date"],
              input[type="url"],
              input[type="password"],
              textarea {
                width: 100%;
                background: transparent;
                border-bottom: 1px solid var(--text_color);
                padding: 0.75rem 0;
                font-size: 14px;

                &::placeholder {
                  color: var(--text_color);
                  opacity: 0.7;
                }

                &:focus {
                  outline: none;
                }
              }

              &:nth-child(11) {
                margin-top: 1.5rem;

                .gfield_label {
                  font-weight: 600;
                }

                .ginput_container {
                  input {
                    margin-block: 1em;
                  }
                }
              }
            }
            .hidden_label {
              label,
              .screen-reader-text {
                display: none;
              }
            }
            .gfield--width-full {
              grid-column: span 2 / span 2;
            }
            fieldset {
              padding: 1.5rem 0;

              legend {
                display: none;
              }
            }
          }
        }
        .gform_footer {
          input[type="submit"] {
            width: auto;
            background: var(--text_color);
            color: #fff;
            border: none;
            padding: 0.75rem 2.5rem;
            font-size: 14px;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 1px;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            display: inline;
          }
        }
      }
    }

    img.hero-img {
      width: 55%;
      margin-right: -25%;
      height: 120vh;
      object-fit: cover;
    }
  }
}

@media screen and (max-width: 1024px) {
  .hero-contact {
    margin-top: -70px;

    .wrap {
      .content {
        .cols {
          flex-wrap: wrap;

          .col {
            width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .hero-contact {
    margin-top: 0;

    .wrap {
      flex-wrap: wrap-reverse;

      .content {
        width: 100%;
        padding-right: 0;

        h1 {
          font-size: 42px;
        }

        .gform_wrapper {
          * {
            color: var(--text_color);
          }

          .gform-body {
            .gform_fields {
              display: flex;
              flex-wrap: wrap;
              gap: 15px;

              .gfield {
                width: 100%;
              }
            }
          }
        }
      }

      img.hero-img {
        width: 100%;
        height: auto;
      }
    }
  }
}
