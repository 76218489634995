section.featured-image {
    .featured-image-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .header-main {
            display: flex;
            align-items: center;
            .line {
                margin: 0 0 0 3rem;
                content: '';
                height: 1px;
                width: 200px;
                margin-top: 4px;
                background: var(--primary-color);
            }
        }
    }

    .featurd-image-container {
        padding-top: 50px;
        width: 100vw;
        
        a {
            text-decoration: none;
        }

        .featurd-image-content {
            h3 {
                padding-top: 10px;
            }
            img {
                width: 100%;
                height: 410px;
                object-fit: cover;
            }
        }
    }
}

.swiper-container {
    width: 100%;
}

@media screen and (max-width: 890px) {
    section.featured-image {
        .featurd-image-container {
            .featurd-image-content {
                img {
                    height: 350px;
                }
            } 
        } 
    } 
}

@media screen and (max-width: 760px) {
    section.featured-image {
        .featurd-image-container {
            .featurd-image-content {
                img {
                    height: 280px;
                }
            } 
        } 

        .featured-image-header {
            flex-direction: column;
            align-items: flex-start;

            .header-main {
                
                .line {
                    display: none;
                }
            } 
        } 
    } 
}