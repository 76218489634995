.fac-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 1rem 5rem;

    @media screen and (max-width: 1023px) {
        padding: 1rem;
    }

    .faq-question {

        .faq-question-container {
            display: flex;
            justify-content: center;
            flex-direction: column;
            text-align: left;
            .faq-question-title {
                display: flex;
                justify-content: space-between;


                &:hover {
                    cursor: pointer;
                }

                h3 {
                    font-size: 26px;
                    font-weight: bolder;
                    padding-right: 1rem;

                    @media screen and (max-width: 1023px) {
                        font-size: 18px;
                    }
                }
                .faq-question-image {
                    border: 1px solid var(--text_color);
                    filter: invert(0);
                    border-radius: 50%;
                    width: 25px;
                    height: 25px;
                    padding: 5px;

                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }
            .faq-question-content {
                color: var(--text_color);
                width: 65%;
                padding-top: 1rem;
            }
        }
    }
    .active {
        opacity: 1;
        .faq-question-container {
            .faq-question-title {
                .faq-question-image {
                    transform: rotate(180deg);
                }
            }
        }
    }
}
