.product-slider {
    .slider {
        width: 70%;
        margin-left: -20%;

        .slider-item {
            height: 560px;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
    }

    .col-content {
        width: 50%;
        padding-left: 50px;

        h2 {
            margin-bottom: 24px;
        }

        .slider-nav {
            padding-top: 34px;

            .slider-nav-item {
                margin-bottom: 26px;
                position: relative;

                span {
                    font-size: 24px;
                    cursor: pointer;
                    opacity: 50%;

                    &:hover {
                        opacity: 100%;
                    }
                }

                .active-border {
                    position: absolute;
                    height: 1px;
                    width: 220px;
                    top: 12px;
                    left: -240px;
                    background: var(--primary-color);
                    content: '';
                    margin-left: auto;
                }

                &:hover {
                    color: var(--primary-color);
                    transition: .1s ease-in;
                }
            }

            .slider-nav-item.active {
                span {
                    opacity: 100%;
                }
            }
        }

        .readmore {
            display: block;
            margin-top: 40px;
        }
    }
}

@media screen and (max-width: 760px) {
    .product-slider {
        flex-direction: column;

        .slider {
            width: 100%;
            margin-left: unset;

            .slider-item {
                height: 320px;
            }
        }
        
        .col-content {
            width: 100%;
            padding-left: unset;
            display: flex;
            flex-direction: column;

            h2 {
                order: 2;
            }

            p {
                order: 3;
            }

            .readmore {
                order: 4;
                margin-top: 6px;
            }

            .slider-nav {
                width: calc(100% + 15px);
                padding-top: 12px;
                order: 1;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                overflow-x: auto;
                scroll-behavior: smooth;

                .slider-nav-item {
                    margin-right: 26px;
                    display: block;

                    span {
                        display: block;
                        white-space: nowrap; 
                    }

                    .active-border {
                        display: none;
                    }
                }
            }
        }
    }
}