section.hero-about {
    margin-top: -69px;
    position: relative;

    .col-intro {
        width: 40%;
        position: relative;
        z-index: 10;

        h1 {
            font-size: 70px;
            margin-bottom: 20px;

            span {
                font-size: 70px;
            }
        }

        .intro {
            max-width: 60%;
        }
    }

    .hero-img {
        width: 96%;
        height: 725px;
        margin-right: -25%;
        margin-left: -20%;
        position: relative;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }



    .scrolldown {
        transform: rotate(90deg);
        position: absolute;
        left: -90px;
        bottom: 0;

        span {
            font-size: 14px;
            text-transform: uppercase;
            display: block;
            margin-right: 12px;
        }

        .border-fff {
            content: '';
            height: 1px;
            width: 180px;
            background: var(--text_color);
        }
    }
}

@media screen and (max-width: 1180px) {
    section.hero-about {
        .hero-img {
            width: 85%;
        }
    }
    
}

@media screen and (max-width: 960px) {
    section.hero-about {
        position: relative;
        height: auto;
        margin-bottom: 1rem;
        margin-top: 100px;

        .scrolldown {
            display: none;
        }

        .hero-img {
            height: 450px;
            width: 100%;
            margin: 0;

            img {
                object-fit: cover;
            }
        } 

        .col-intro {
            flex-wrap: wrap;
            flex-direction: column;
            height: auto;
            width: 100%;

            h1 {
                font-size: 32px;

                span {
                    font-size: 32px;
                }
            }

            .intro  {
                width: 100%;
                max-width: unset;
                margin-bottom: 1rem;
                padding-top: 15px;
            }
        }
    }
}