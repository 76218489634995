footer.footer {
    padding-bottom: 15px;

    .footer-head {
        .logo {
            width: 200px;

            img {
                width: 100%;
                height: auto;
            }
        }

        .border-orange {
            width: calc(100% - 240px);
            height: 1px;
            background-color: var(--primary-color);  
            content: '';
        }
    }

    .footer-content {
        padding-top: 36px;
        padding-bottom: 66px;

        .nav-big {
            a {
                font-size: 22px;
                font-weight: 700;
                text-transform: uppercase;
                text-decoration: none;
                margin-bottom: 18px;
            }
        }

        .nav-small {
            a {
                font-size: 16px;
                font-weight: 300;
                margin-bottom: 14px;
                text-decoration: none;
            }
        }

        .nav-info {
            .social-row {
                margin-bottom: 26px;

                a {
                    margin-right: 10px;
                }
            }

            .info {
                .top {
                    margin-bottom: 26px;
                }

                a {
                    font-weight: 400;
                    margin-bottom: 12px;
                }

                span {
                    margin-bottom: 12px;
                    font-weight: 300;
                }
            }
        }
    }

    .footer-foot {
        span,a{
            font-size: 12px;
            font-weight: 300;
            text-decoration: none;
        }
    }
}

@media screen and (max-width: 760px) {
    footer.footer {
        .footer-content {
            flex-direction: column;

            .col {
                width: 100%;
                padding-bottom: 20px;
            }

            .nav-info {
                order: 1;
            }

            .nav-big {
                order: 2;

                a {
                    font-size: 18px;
                }
            }

            .nav-small {
                order: 3;
            }
        }
    }
}