.info-pop {
    width: 275px;

    h3 {
        font-weight: 700;
        margin-bottom: 6px;
        font-size: 14px;
    }

    .web-url {
        margin-bottom: 8px;
    }

    a {
        font-size: 12px;
    }

    span {
        display: block;
        margin-top: 8px;
        font-size: 12px;
        
        b {
            font-weight: 700;
        }

        a {
            text-decoration: none;
            font-weight: 300;
        }
    }
}