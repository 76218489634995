section.text_slider {
    h2 {
        text-align: center;
        margin-bottom: 46px;
    }
    .col {
        width: 50%;
    }

    .col-nav {
        padding-right: 15px;

        li.nav-item {
            text-decoration: none;
            list-style: none;
            font-size: 32px;
            font-weight: 500;
            margin-bottom: 40px;
            cursor: pointer;
            opacity: .7;
            
            &:hover {
                opacity: 1;
                transition: .1s ease-in;
            }
        }

        li.active.nav-item {
            color: var(--primary-color);
            opacity: 1;
        }
    }

    .col-content {
    }
}

@media screen and (max-width: 760px) {
    section.text_slider {

        h2 {
            text-align: left;
        }

        .container .flex {
            flex-wrap: wrap;

            .col {
                width: 100%;
            }


            .col-nav {
                width: calc(100% + 15px);
                margin-right: -15px;
                flex-direction: row;
                justify-content: flex-start;
                flex-wrap: nowrap;
                overflow-x: auto;
                scroll-behavior: smooth;
                padding-right: 0;
                padding-bottom: 12px;

                li.nav-item {
                    font-size: 24px;
                    margin-right: 24px;
                    margin-bottom: 12px;
                    display: block;
                    white-space: nowrap; 
                }
            }
        }
    }
}
