section.hero-home {

    .text-content {
        position: relative;
        z-index: 2;

        h1 {
            margin-bottom: -130px;
            font-size: 70px;
            font-weight: 700;
            text-transform: uppercase;
            color: var(--text_color);
            margin-top: 0;  
        }

        .text {
            max-width: 370px;
        }
    }

    .hero-visual {
        position: relative;
        height: 615px;

        .scrolldown {
            transform: rotate(90deg);
            position: absolute;
            left: -90px;

            span {
                font-size: 14px;
                text-transform: uppercase;
                display: block;
                margin-right: 12px;
            }

            .border-fff {
                content: '';
                height: 1px;
                width: 180px;
                background: var(--text_color);
            }
        }

        .hero-img {
            width: 105%;
            height: 100%;
            margin-right: -20%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .hero-vid {
            width: 105%;
            height: 100%;
            margin-right: -20%;
            position: relative;

            video {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
    }
}

@media screen and (max-width: 960px) {
    section.hero-home {
        position: relative;
        height: auto;
        margin-bottom: 1rem;
        margin-top: -3rem;

        .hero-visual {
            height: 450px;
            position: relative;

            .hero-vid {
                margin-right: unset;
                height: 380px;
            }
        } 

        .text-content {
            flex-wrap: wrap;
            flex-direction: column;
            height: auto;

            h1 {
                margin-bottom: 1.5rem;
            }

            .big {
                font-size: 42px;
            }

            .text {
                width: 100%;
                max-width: unset;
                margin-bottom: -85px;
                padding-top: 15px;
            }
        }
    }
}