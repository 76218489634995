.single-story {
    padding-top: 60px;
    padding-bottom: 60px;

    p {
        img {
            margin: 36px 0;
        }
    }
    
    h2 {
        font-size: 32px;
    }

    h3 {
        font-size: 24px;
    }

    h4 {
        font-size: 20px;
    }

    h5,h6 {
        font-size: 18px;
    }

    .header {
        img, video {
            width: 100%;
            height: auto;
        }

        h1 {
            font-size: 42px;
        }

        .meta {
            .post-data {

                div,span {
                    display: flex;
                    margin-right: 8px;
                }

                .reading-time {
                    white-space: nowrap;
                    img {
                        width: 15px;
                        margin: 0 6px 0 0;
                    }
                }
            }

        }
    }

    .content {
        padding-left: 15px;
        padding-right: 15px;

        video, img, .wp-video {
            width: 100% !important;
            height: auto;
        }
    }
}

@media screen and (max-width: 760px) {
    .single-story {
        padding-top: 0;

        .content {
            padding-left: 0;
            padding-right: 0;

        }


        .meta {
            flex-wrap: wrap;

            .post-data {
                width: 100%;
                justify-content: center;
                
                div,span {
                    display: flex;
                    margin-right: 8px;
                }

                .reading-time {
                    img {
                        width: 15px;
                        margin: 0 6px 0 0;
                    }
                }
            }


            .social-share {
                width: 100%;
                justify-content: center;
                padding-top: 26px;
            }
        }

        .header {
            h1 {
                font-size: 36px;
            }
        }
        
        h2 {
            font-size: 28px;
        }
    
        h3 {
            font-size: 22px;
        }
    
        h4 {
            font-size: 18px;
        }
    
        h5,h6 {
            font-size: 16px;
        }
    }
}