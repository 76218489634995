@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@300;400;500;600;700&display=swap');

@font-face {
    font-family: 'Nord';
    src: url('./assets/fonts/Nord-BoldItalic.eot');
    src: url('./assets/fonts/Nord-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Nord-BoldItalic.woff2') format('woff2'),
        url('./assets/fonts/Nord-BoldItalic.woff') format('woff'),
        url('./assets/fonts/Nord-BoldItalic.ttf') format('truetype'),
        url('./assets/fonts/Nord-BoldItalic.svg#Nord-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Nord';
    src: url('./assets/fonts/Nord-Black.eot');
    src: url('./assets/fonts/Nord-Black.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Nord-Black.woff2') format('woff2'),
        url('./assets/fonts/Nord-Black.woff') format('woff'),
        url('./assets/fonts/Nord-Black.ttf') format('truetype'),
        url('./assets/fonts/Nord-Black.svg#Nord-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nord Book';
    src: url('./assets/fonts/Nord-Book.eot');
    src: url('./assets/fonts/Nord-Book.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Nord-Book.woff2') format('woff2'),
        url('./assets/fonts/Nord-Book.woff') format('woff'),
        url('./assets/fonts/Nord-Book.ttf') format('truetype'),
        url('./assets/fonts/Nord-Book.svg#Nord-Book') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nord';
    src: url('./assets/fonts/Nord-Bold.eot');
    src: url('./assets/fonts/Nord-Bold.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Nord-Bold.woff2') format('woff2'),
        url('./assets/fonts/Nord-Bold.woff') format('woff'),
        url('./assets/fonts/Nord-Bold.ttf') format('truetype'),
        url('./assets/fonts/Nord-Bold.svg#Nord-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nord';
    src: url('./assets/fonts/Nord-ThinItalic.eot');
    src: url('./assets/fonts/Nord-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Nord-ThinItalic.woff2') format('woff2'),
        url('./assets/fonts/Nord-ThinItalic.woff') format('woff'),
        url('./assets/fonts/Nord-ThinItalic.ttf') format('truetype'),
        url('./assets/fonts/Nord-ThinItalic.svg#Nord-ThinItalic') format('svg');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Nord';
    src: url('./assets/fonts/Nord-Medium.eot');
    src: url('./assets/fonts/Nord-Medium.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Nord-Medium.woff2') format('woff2'),
        url('./assets/fonts/Nord-Medium.woff') format('woff'),
        url('./assets/fonts/Nord-Medium.ttf') format('truetype'),
        url('./assets/fonts/Nord-Medium.svg#Nord-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nord';
    src: url('./assets/fonts/Nord-BlackItalic.eot');
    src: url('./assets/fonts/Nord-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Nord-BlackItalic.woff2') format('woff2'),
        url('./assets/fonts/Nord-BlackItalic.woff') format('woff'),
        url('./assets/fonts/Nord-BlackItalic.ttf') format('truetype'),
        url('./assets/fonts/Nord-BlackItalic.svg#Nord-BlackItalic') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Nord Book';
    src: url('./assets/fonts/Nord-BookItalic.eot');
    src: url('./assets/fonts/Nord-BookItalic.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Nord-BookItalic.woff2') format('woff2'),
        url('./assets/fonts/Nord-BookItalic.woff') format('woff'),
        url('./assets/fonts/Nord-BookItalic.ttf') format('truetype'),
        url('./assets/fonts/Nord-BookItalic.svg#Nord-BookItalic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Nord';
    src: url('./assets/fonts/Nord-Light.eot');
    src: url('./assets/fonts/Nord-Light.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Nord-Light.woff2') format('woff2'),
        url('./assets/fonts/Nord-Light.woff') format('woff'),
        url('./assets/fonts/Nord-Light.ttf') format('truetype'),
        url('./assets/fonts/Nord-Light.svg#Nord-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nord';
    src: url('./assets/fonts/Nord-Italic.eot');
    src: url('./assets/fonts/Nord-Italic.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Nord-Italic.woff2') format('woff2'),
        url('./assets/fonts/Nord-Italic.woff') format('woff'),
        url('./assets/fonts/Nord-Italic.ttf') format('truetype'),
        url('./assets/fonts/Nord-Italic.svg#Nord-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Nord';
    src: url('./assets/fonts/Nord-MediumItalic.eot');
    src: url('./assets/fonts/Nord-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Nord-MediumItalic.woff2') format('woff2'),
        url('./assets/fonts/Nord-MediumItalic.woff') format('woff'),
        url('./assets/fonts/Nord-MediumItalic.ttf') format('truetype'),
        url('./assets/fonts/Nord-MediumItalic.svg#Nord-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Nord';
    src: url('./assets/fonts/Nord-Regular.eot');
    src: url('./assets/fonts/Nord-Regular.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Nord-Regular.woff2') format('woff2'),
        url('./assets/fonts/Nord-Regular.woff') format('woff'),
        url('./assets/fonts/Nord-Regular.ttf') format('truetype'),
        url('./assets/fonts/Nord-Regular.svg#Nord-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nord';
    src: url('./assets/fonts/Nord-LightItalic.eot');
    src: url('./assets/fonts/Nord-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Nord-LightItalic.woff2') format('woff2'),
        url('./assets/fonts/Nord-LightItalic.woff') format('woff'),
        url('./assets/fonts/Nord-LightItalic.ttf') format('truetype'),
        url('./assets/fonts/Nord-LightItalic.svg#Nord-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Nord';
    src: url('./assets/fonts/Nord-Thin.eot');
    src: url('./assets/fonts/Nord-Thin.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Nord-Thin.woff2') format('woff2'),
        url('./assets/fonts/Nord-Thin.woff') format('woff'),
        url('./assets/fonts/Nord-Thin.ttf') format('truetype'),
        url('./assets/fonts/Nord-Thin.svg#Nord-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}



:root {
    --background_color: #F2F0EF;
    --text_color: #272425;
    --primary-color: #BB9C6F;
}

$transition: cubic-bezier(0.97, 0.01, 0.36, 0.99);

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--background_color);
}

* {
    font-family: 'Kumbh Sans', sans-serif;
    color: var(--text_color);
}

h1, h2, h3, h4, h5 {
    font-family: 'Nord';
    
    * {
        font-family: 'Nord';
    }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.spacer {
    display: block;
    content: '';
}

.font-orange {
    color: var(--primary-color);
}

.background-orange {
    background: var(--primary-color);
}

a {
    text-decoration: underline;
    font-weight: 600;
}

p {
    line-height: 26px;
    margin: 0 0 20px 0;
    font-weight: 300;

    a {
        text-decoration: underline;
        font-weight: 600;
    }
}

h1 {
    font-size: 65px;
    font-weight: 700;
    text-transform: uppercase;
}

h2 {
    font-size: 45px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 24px 0 12px 0;
}

.App {
    overflow-x: hidden;
}

main {
    min-height: 110vh;
}

.container {
    max-width: 1280px;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
}

.container-small {
    max-width: 790px;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
}

.container-big {
    max-width: 1640px;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
}

.archive {
    padding-top: 90px;

    .archive-header {
        .intro {
            h1 {
                font-size: 70px;
                margin-bottom: 20px;
                margin-top: 0;
    
                span {
                    font-size: 70px;
                }
            }

            .intro-text {
                max-width: 370px;
            }
        }
    }
}

.container-small > h2 > a {
    background: #bb9c6f;
    color: #fff;
    display: block;
    text-decoration: none;
    padding: 2rem 3rem;
    font-size: 36px;
}

@media screen and (max-width: 768px) {
    .container-small > h2 > a {
        padding: 1.5rem 1rem;
        font-size: 22px;
    }
}

@media screen and (max-width: 1024px) {
    .archive {
        .archive-header {
            .intro {
                flex-wrap: wrap;

                h1 {
                    width: 100%;
        
                }
    
                .intro-text {
                    width: 100%;
                    max-width: 100%;
                    padding-bottom: 20px;
                }
            }
        }
    }
}

@media screen and (max-width: 760px) {
    .archive {
        .archive-header {
            .intro {
                h1 {
                    font-size: 36px;
                }
            }
        }
    }

    .spacer {
        max-height: 75px !important;
    }

    p {
        font-size: 14px;
        line-height: 24px;
        font-weight: 300;
    
        a {
            font-size: 14px;
            line-height: 24px;
        }
    }

    h1 {
        font-size: 42px;
    }
    
    h2 {
        font-size: 28px;
    }
}