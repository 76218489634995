section.filter {
    display: flex;
    gap: 3rem;
    align-items: center;

    .filter__item {
        input[type=checkbox] {
            visibility: hidden;
            width: 0;

            & + label {
                display: flex;
                align-items: center;
                white-space: nowrap;

                &::before {
                    content: '';
                    width: 12px;
                    height: 12px;
                    border: 1px solid var(--text_color);
                    margin-right: .5rem;
                }
            }

            &:checked {
                & + label {
                    &::before {
                        background: var(--text_color);
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 760px) {
    section.filter {
        width: calc(100% + 15px);
        margin-right: -15px;
        gap: 20px;
        overflow: auto;
        scroll-behavior: smooth;
        padding-bottom: 12px;
    }
}