.info-product {
  .sidebar {
    width: 50%;

    h3 {
      font-size: 25px;
      font-weight: 600;
      margin-bottom: 24px;
    }

    .second-h3 {
      margin-top: 24px;
    }

    .item {
      .kenmerk {
        display: block;
        margin-bottom: 22px;
        font-size: 17px;
        font-weight: 300;

        b {
          font-weight: 700;
        }
      }
    }

    .download {
      img {
        margin-right: 8px;
      }
    }
  }

  .content {
    width: 50%;

    h2 {
      font-size: 32px;
      font-weight: 600;
      margin-bottom: 24px;
      text-transform: unset;
    }

    img {
      display: inline-block;
      margin-right: 0.5rem;
    }
  }
}

@media screen and (max-width: 960px) {
  .info-product {
    flex-wrap: wrap-reverse;

    .sidebar {
      width: 100%;
    }

    .content {
      width: 100%;
      margin-bottom: 3rem;
    }
  }
}
