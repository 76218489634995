.video-banner {
    .video {
        width: 120%;
        margin-left: -20%;
        position: relative;
        height: 530px;

        video {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
    }
}