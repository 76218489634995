section.duo-nav {
    .col-50 {
        width: 50%;

        h3 {
            font-size: 40px;
            font-weight: 700;
            text-transform: uppercase;
            margin-bottom: 12px;
        }

        p {
            max-width: 300px;
            margin-bottom: 30px;
        }

        nav {
            a {
                margin-bottom: 22px;
                text-decoration: underline;
                font-weight: 600;
            }
        }
    }   

    .col-right {
        text-align: right;
    }
}

@media screen and (max-width: 760px) {
    section.duo-nav {
        .wrap {
            flex-wrap: wrap;
        }

        .col-50 {
            width: 100%;
            margin-bottom: 26px;

            h3 {
                font-size: 30px;
            }

            p {
                font-size: 14px;
            }

            nav {
                a {
                    font-size: 16px;
                }
            }
        }

        .col-right {
            text-align: left;
            align-items: flex-start;
        }
    }
}